module.exports = [{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.gstatic.com"],"web":[{"name":"DM Sans","file":"https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,700;1,400"},{"name":"Fugaz One","file":"https://fonts.googleapis.com/css2?family=Fugaz+One"},{"name":"Dosis","file":"https://fonts.googleapis.com/css2?family=Dosis:wght@500"},{"name":"Roboto Mono","file":"https://fonts.googleapis.com/css2?family=Roboto+Mono"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"/opt/build/repo/node_modules/gatsby-remark-images-contentful","id":"381fa5ec-c58b-5a57-b868-0ed837530113","name":"gatsby-remark-images-contentful","version":"5.21.0","modulePath":"/opt/build/repo/node_modules/gatsby-remark-images-contentful/index.js","pluginOptions":{"plugins":[],"maxWidth":1024,"backgroundColor":"none","loading":"eager"},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"extensions":[".mdx"],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
